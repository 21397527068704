<template>
    <div>
        <!-- /.modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <form @submit.prevent="save" class="card">
                <div class="modal-content">
                <div class="modal-header bg-primary">
                    <h5 class="modal-title" id="exampleModalLabel"> <i class="fa fa-plus"></i> {{ form.id ? "Edit Game" : "Add Game"}}</h5>
                </div>
                <div class="modal-body">
                    <div class="col">
                        <div class="form-group">
                            <label for="game_title" class="col-form-label">Game Name</label>
                            <input type="text" class="form-control" id="recipient-name" v-model="form.game_title" :disabled="loading">
                        </div>
                            <div class="form-group">
                            <label for="game_code" class="col-form-label">Game Code<span class="text-red">*</span></label>
                            <input type="text" class="form-control" id="recipient-email" required v-model="form.game_code" :disabled="loading">
                        </div>
                        <div class="form-group">
                            <label for="game_url" class="col-form-label">URL Game</label>
                            <input type="text" class="form-control" id="recipient-password" v-model="form.game_url" :disabled="loading">
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group" id="div_image" @click="defaultBtnImage()" style="cursor: pointer">
                                    <img :src="form.game_thumbnail" v-if="form.game_thumbnail" width="100%" height="100%" class="img-thumbnail" />
                                    <img src="@assets/no-image.png" v-else width="100%" height="100%" class="img-thumbnail" alt="No Image">
                                    <input type="file" accept="image/*" id="default-btn" @change="handleImage" :disabled="loading" hidden />
                                    <div class="bg-primary text-center p-2" >
                                        Upload Photo<span class="text-red">*</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5" style="position: absolute; top: 62%; left: 30%;">
                                <div>
                                    <span class="text-red">*</span> Photo max 1MB
                                </div>
                                <span class="text-red">*</span> File Photo JPG, or PNG
                            </div>
                        </div>
                        <label class="switch">
                            <input type="checkbox" @click="toggleCheckbox" v-model="form.status">
                            <div class="slider round"></div>
                        </label>
                    <!-- </div> -->
                </div>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                     <button type="submit" class="btn btn-primary" :disabled="loading">
                        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
                        {{ loading && form.id ? 'Saving ...' : form.id ? 'Save': loading && !form.id ? 'Saving ...' : 'Save' }}
                    </button>
                </div>
                </div>
                </form>
            </div>
        </div>

       <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark capitalize">
                        Games
                    </h1>
                </div><!-- /.col -->
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right ">
                        <li class="ml-3"><button type="button" class="btn btn-block btn-primary btn-lg"  @click="showModal"><span class="fas fa-plus"> </span>  Add Game</button></li>
                    </ol>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container-fluid -->
    </div>
        <!-- card list games -->
        <div style="display: flex; flex-flow: row wrap;">
            <div v-for="(game, index ) in games" :key="index" style="margin: 8px;">
              <div class="card" style="width: 240px; height: 218px; margin-left: 15px; border-radius: 10px;">
                  <div class="row" style="margin: 0">
                      <img @click="clickDetail(game, index)" v-if="game.status === true" :src="game.game_thumbnail" width="100%" height="150px" style="border-radius: 10px 10px 0 0; object-fit: contain;">
                      <img @click="clickDetail(game, index)" v-else :src="game.game_thumbnail" width="100%" height="150px" style="border-radius: 10px 10px 0 0; object-fit: contain; filter: grayscale(1);">
                      <div class="col" style="margin: 10px">
                          <h5>{{ game.game_title }}</h5>
                      </div>
                      <div>
                        <label class="switch" style="margin-top: 20px; margin-right: 10px;">
                            <input type="checkbox" @click="toggleCheckboxGames(index)" v-model="game.status">
                            <div class="slider round"></div>
                        </label>
                      </div>
                  </div>
              </div>
            </div>
        </div>
                
    </div>
</template>


<script>
import DataTables from '@components/DataTables.vue'
const {console} = window

export default {
     components: {
        // DataTables
    },

    data() {
        return {
            active: false,
            errorDialog: null,
            errorText: '',
            uploadFieldName: 'file',
            error: undefined,
            loading: false,
            text_validation : null,
            maxSize: 1024,
            games: {},
            form : {
                id: null,
                game_title:"",
                game_code:"",
                game_url:"",
                game_thumbnail:"",
                description:"",
                status: false,            
            }, 
            disabled: false,
        }
    },
    mounted() {
        this.getDataGames()
    },
    props: {
      value: Object,
    },
    computed : {
        console: () => console,
    },
    methods: {
        add() {
        },
        getDataGames() {
            this.Api.get(`/game?sort=id&&size=20`).then(res =>{
                this.games = res.data.content
            })
            .catch((e) =>{

            })  
        },
        clickDetail(data, id) {
            if(data){
                this.form.id=data.id;
                this.form.game_title = data.game_title;
                this.form.game_code = data.game_code;
                this.form.game_url = data.game_url;
                this.form.game_thumbnail = data.game_thumbnail
                this.form.status = data.status
                $('#exampleModal').modal('show');
            }
        },
        toggleCheckbox() {
            this.form.status = !this.form.status
            this.$emit('setCheckboxVal', this.form.status)
        },
        toggleCheckboxGames(i) {
            this.games[i].status = !this.games[i].status
            this.$emit('setCheckboxVal', this.games[i].status)

            this.Api.put(`/game/${this.games[i].id}`, this.games[i]).then(res => {
                  this.loading = false
                  alert('Success!')
                //   this.getDataGames();
                }).catch((e)  => {
                  this.loading = false
                  this.error = e.response.data.message
                  this.text_validation = "Submit Failed";  
            })
        },
        resetGame() {
            this.form = {
                game_title:"",
                game_code:"",
                game_url:"",
                game_thumbnail:"",
                description:"",
                status: false
            }
        },
        showModal() {
            this.form.id=null;
            this.form.name=null;
            this.form.image=null;
            this.game_title=null,
            this.game_code=null,
            this.game_url=null,
            this.form.game_thumbnail=null,
            this.description=null,
            this.status=false
          $("#exampleModal").modal("show");
        },
        defaultBtnImage(){
            const defaultbtn = document.querySelector("#default-btn");
            defaultbtn.click();
        },
        handleImage(e) {
            const selectedImage = e.target.files[0];
            if(selectedImage.type === "image/png" || selectedImage.type === "image/jpeg") {
              if(selectedImage.size <= 1024 * 1024) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.form.game_thumbnail = e.target.result;
                }
                reader.readAsDataURL(selectedImage);
              } else {
                alert('File too big (> 1MB)');
              }
            } else {
              alert('File is not supported')
            }
        },
        save() {
            this.loading = true
            if(this.form.id === null) {
                this.Api.post('/game', this.form).then(res => {
                  this.loading = false
                  this.getDataGames();
                  this.resetGame();
                  $('#exampleModal').modal('hide');
                }).catch((e)  => {
                  this.loading = false
                  this.error = e.response.data.message
                  this.text_validation = "Submit Failed";   
                })
            } else {
                this.Api.put(`/game/${this.form.id}`, this.form).then(res => {
                  this.loading = false
                  this.getDataGames();
                  $('#exampleModal').modal('hide');
                }).catch((e)  => {
                  this.loading = false
                  this.error = e.response.data.message
                  this.text_validation = "Submit Failed";             
                })
            }
        },
        refresh(){
            this.config = { 
                url: this.Api.base + '/game'
            }
        }
    },
}
</script>
